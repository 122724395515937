import {
  action,
  autorun,
  comparer,
  computed,
  decorate,
  observable,
  reaction,
} from 'mobx';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  CaseClient,
  EmployeeCasesStore,
  GroupStore,
  DownloadUtils,
  HalUtils,
  LoginStore,
  PermissionStore,
  SingletonStore,
  getUnaccountedMap,
  MedicationUtils,
  IncidentDescriptionStore,
} from 'common';
import ActivityDescriptionStore from '../../../activity/stores/ActivityDescriptionStore';
import ActivityStore from '../../../activity/stores/Store';
import CaseAccessesStore from '../CaseAccessesStore';
import CaseTemplate from '../../CaseTemplate';
import CaseUtils from '../../../utils/CaseUtils';
import NotificationStore from '../../../stores/NotificationStore';
import PatientCasesStore from '../../../stores/PatientCasesStore';
import TemporaryPermissionsSettingsStore from '../../../stores/TemporaryPermissionsSettingsStore';
import { compare } from '../../../utils/compare';
import { updatedIncidentList } from '../../../utils/IncidentUtils';
import { getAssessmentsHeadlines } from '../../../utils/AssessmentUtils';
import { toast } from 'react-toastify';
import {
  CREATE_IN_PROGRESS,
  SYNC_IN_PROGRESS,
  SYNC_SUCCESS,
} from '../../../settings/stores/CaseIntegrationStore/const';

const postProcessEvents = (events, newEvents, dateField, descriptionField) => {
  if (newEvents) {
    newEvents.forEach(e => {
      const event = {};
      event.id = e.id;
      event.patient = e.patient;
      event.instance = e.instance;
      event.environment = e.environment;
      event.source = e.source;

      if (typeof dateField === 'string') event.eventDate = moment(e[dateField]);
      else event.eventDate = dateField(e);

      if (typeof descriptionField === 'string')
        event.eventDescription = e[descriptionField];
      else event.eventDescription = descriptionField(e);

      events.push(event);
    });
  }
};

const DEFAULT_SORT = 'eventDate,asc';

const parseSort = sort => {
  const splits = sort.split(',');
  return {
    sortBy: splits[0],
    sortDir: splits[1],
  };
};

const SYNC_ERROR = (
  <span>
    There was an issue syncing the Protenus case. Please pause for 5 minutes and
    try again. If this issue happens again, contact{' '}
    <a
      href="https://help.protenus.com/hc/en-us/requests/new"
      target="_blank"
      rel="noopener noreferrer"
    >
      Protenus Customer Support
    </a>
  </span>
);

class CaseStore extends SingletonStore {
  constructor({ incidentDescriptionStore = {} }) {
    super();

    this.updatedIncidentList = updatedIncidentList;
    this.incidentDescriptionStore = incidentDescriptionStore;

    reaction(
      () => [
        this.caseId,
        this.incidentDescriptionStore.defsMap,
        GroupStore.groups,
      ],
      () => {
        this.refresh();
      }
    );
    reaction(
      () => [this.userId, GroupStore.groups],
      ([userId]) => {
        if (userId) {
          EmployeeCasesStore.fetch(userId);
        }
      }
    );
    reaction(
      () => [this.patientId, GroupStore.groups],
      ([patientId]) => {
        if (patientId) {
          PatientCasesStore.fetch(patientId);
        }
      }
    );
    reaction(
      () => [this.case],
      () => {
        this.setNewTypeCategory(null);
        this.setNewUserType(null);
        this.setNewOwner(null);
        this.setNewResolution(null);
      }
    );
    reaction(
      () => [this.number],
      () => {
        if (this.type === 'diversion') {
          this.caseIncidentsLoading = true;
        }
        if (this.number) {
          this.setCaseMedicationIncidents();
        }
      }
    );
    reaction(
      () => [TemporaryPermissionsSettingsStore.duration],
      () => {
        this.temporaryPermissionEndDate = moment().add(
          TemporaryPermissionsSettingsStore.duration,
          'days'
        );
      }
    );
    autorun(() => {
      if (CaseAccessesStore) {
        CaseAccessesStore.case = this.result;
      }
    });
  }

  // Observable
  caseId = null;
  editingNote = null;
  deletingNote = null;
  draftNotes = '';
  draftAssessment = '';
  deleting = false;
  deletingAction = null;
  deletedAttachment = null;
  deletingAttachment = null;
  reopeningCaseFromTimeline = false;
  uploadingAttachment = null;
  uploadingNote = false;
  editingAssessment = false;
  deletingAssessment = false;
  currentAction = null;
  newTypeCategory;
  newUserType;
  newOwner;
  newResolution;
  temporaryPermissionCreate = false;
  temporaryPermissionEndDate = moment().add(
    TemporaryPermissionsSettingsStore.duration,
    'days'
  );
  temporaryPermissionReason = null;
  commentFormActive = false;
  incidentFilterParams = {};
  sort = DEFAULT_SORT;
  isSyncing = false;
  syncLoadingMsg = '';
  caseMedicationIncidents = [];
  caseIncidentsLoading = false;

  // Computed
  get case() {
    return this.result || {};
  }

  // Computed
  get type() {
    return CaseUtils.whichCaseType(this.case);
  }

  // Computed
  get patient() {
    return this.case && this.case.patientSnapshot;
  }

  // Computed
  get typeCategory() {
    return this.case && this.case.category;
  }

  // Computed
  get isSelfAccess() {
    return /self/i.test(this.typeCategory.name || '');
  }

  // Computed
  get userType() {
    return this.case && this.case.userType;
  }

  // Computed
  get patientId() {
    // Do not use the id from the snapshot. It may be out of date, plus the
    // snapshot is for demographic purposes only.
    return this.case && this.case.patient && this.case.patient.id;
  }

  // Computed
  get patientFullName() {
    return this.patient && this.patient.fullName;
  }

  // Computed
  get user() {
    return this.case && this.case.userSnapshot;
  }

  // Computed
  get userId() {
    // Do not use the id from the snapshot. It may be out of date, plus the
    // snapshot is for demographic purposes only.
    return this.case && this.case.user && this.case.user.id;
  }

  // Computed
  get userFullName() {
    return this.user && this.user.fullName;
  }

  // Computed
  get title() {
    const c = this.case;
    if (!c) return '';
    const stripTZ = this.type === 'GRC';
    return CaseTemplate.title(c.title, c.minEventDate, c.maxEventDate, stripTZ);
  }

  // Computed
  get lastModified() {
    return this.case.lastModified;
  }

  // Computed
  get lastUpdatedByAG() {
    const c = this.case;
    if (!c) return '';
    return c.lastUpdatedByAG;
  }

  // Computed
  get maxEventDate() {
    return this.case.maxEventDate;
  }

  // Computed
  get minEventDate() {
    return this.case.minEventDate;
  }

  // Computed
  get number() {
    return this.case.number;
  }

  // Computed
  get medicationIncidents() {
    return this.caseMedicationIncidents || [];
  }

  // Computed
  // @return {Array} an Array containing Arrays of incidents filtered by assessment's min, max date
  get incidentsByPeriod() {
    const periods = this.analyticAssessments.map(a => ({
      min: a.minEventDate,
      max: a.maxEventDate,
    }));
    return periods.map(p =>
      this.medicationIncidents.filter(inc => {
        const incidentDate = inc.startTime || inc.endTime;
        return (
          moment(incidentDate).isSameOrAfter(p.min) &&
          moment(incidentDate).isSameOrBefore(p.max)
        );
      })
    );
  }

  // Computed
  get incidentRangeFilterOptions() {
    const defaultOpt = { name: 'All Time Ranges' };
    const rangeOpts = [];

    if (this.analyticAssessments?.length) {
      const maxAssessmentDate = this.analyticAssessments.reduce(
        (max, { maxEventDate }) => {
          const maxEventDateMoment = moment(maxEventDate);
          if (!max || maxEventDateMoment.isAfter(max)) {
            return maxEventDateMoment;
          }

          return max;
        },
        null
      );

      const formattedLastMaxISO = maxAssessmentDate.toISOString();
      const formattedLastMaxLocal = maxAssessmentDate.format('l');

      const outOfRangeIncidents = this.medicationIncidents.filter(inc =>
        maxAssessmentDate.isBefore(inc.startTime)
      );

      if (outOfRangeIncidents.length) {
        rangeOpts.push({
          name: `${formattedLastMaxLocal} - Present`,
          value: `${formattedLastMaxISO},${moment().toISOString()}`,
        });
      }
    }

    this.analyticAssessments.forEach(a => {
      const formattedLocal = {
        min: moment(a.minEventDate).format('l'),
        max: moment(a.maxEventDate).format('l'),
      };
      const formattedISO = {
        min: moment(a.minEventDate).toISOString(),
        max: moment(a.maxEventDate).toISOString(),
      };
      rangeOpts.push({
        name: `${formattedLocal.min} - ${formattedLocal.max}`,
        value: `${formattedISO.min},${formattedISO.max}`,
      });
    });

    return [defaultOpt, ...rangeOpts];
  }

  // Computed
  get incidentStatusFilterOptions() {
    return [
      { name: 'All Statuses' },
      { name: 'Unresolved', value: 'null' },
      { name: 'Accurate', value: 'ACCURATE' },
      { name: 'Inaccurate', value: 'INACCURATE' },
      { name: 'AI Updated and Resolved', value: 'AI_UPDATED_AND_RESOLVED' },
      { name: 'Pending', value: 'PENDING' },
    ];
  }

  // Computed
  get incidentTypeFilterOptions() {
    const CATEGORY_MEDICATIONS = 'Medications';
    const CATEGORY_REASONS = 'Reasons';

    const filterOptions = [];
    const defaultIncidentTypeFilterOption = {
      category: '',
      name: 'All Incident Types',
    };
    // Add reasons to filter options
    const reasons = {};
    this.medicationIncidents.forEach(inc => {
      const typeDesc = MedicationUtils.incidentTranslationMap[inc.type];
      if (typeDesc) {
        reasons[inc.type] = typeDesc;
      }
    });
    Object.entries(reasons).forEach(entry =>
      filterOptions.push({
        category: CATEGORY_REASONS,
        name: entry[1],
        value: entry[0],
      })
    );

    // Add medications to filter options
    const medications = {};
    // for the time being we're just grabbing the first medication
    this.medicationIncidents
      .filter(inc => inc.medications && inc.medications.length)
      .forEach(
        inc =>
          (medications[
            inc.medications[0].medicationId
          ] = MedicationUtils.formatMedication(inc.medications[0], true))
      );
    Object.entries(medications).forEach(entry =>
      filterOptions.push({
        category: CATEGORY_MEDICATIONS,
        name: entry[1],
        value: entry[0],
      })
    );

    // sort by incident type name, A-Z
    filterOptions.sort((a, b) => (a.name > b.name ? 1 : -1));

    return [defaultIncidentTypeFilterOption, ...filterOptions];
  }

  // Computed
  get incidentsFilteredByParams() {
    const criteria = this.incidentFilterParams;
    const incidents = [...(this.medicationIncidents || [])].sort((a, b) =>
      compare(b.startTime, a.startTime)
    ); // newest first
    let type = criteria.type || '';
    if (!this.incidentTypeFilterOptions.find(option => option.value === type)) {
      type = '';
    }
    const resolution = criteria.status || '';
    const range = criteria.range ? criteria.range.split(',') : [];

    const filteredByType = type
      ? incidents.filter(
          inc =>
            inc.type === type ||
            inc.medications.find(m => m.medicationId === criteria.type)
        )
      : incidents;

    // filter by range
    const filteredByRange = range.length
      ? filteredByType.filter(
          inc =>
            moment(inc.startTime).isSameOrAfter(range[0]) &&
            moment(inc.endTime).isSameOrBefore(range[1])
        )
      : filteredByType;

    const filteredByResolution = resolution
      ? filteredByRange.filter(inc =>
          resolution === 'UNRESOLVED'
            ? !inc.computedResolution
            : inc.computedResolution === resolution
        )
      : filteredByRange;

    return filteredByResolution || [];
  }

  // Computed
  // @return {Array} an Array containing mapped missing drug Strings
  get unreconciledDrugs() {
    return this.incidentsByPeriod.map(inc =>
      Object.keys(getUnaccountedMap(inc))
    );
  }

  // Computed
  get reasons() {
    const { reasons } = this.case || {};
    return reasons || [];
  }

  // Computed
  get userRoleEmails() {
    const r = this.result && this.result.user;
    const emails = new Set();
    if (r && r.roles) {
      r.roles.forEach(role => {
        if (role.email) emails.add(role.email);
      });
    }
    return [...emails];
  }

  // Computed
  get links() {
    if (this.case) {
      if (this.patient) {
        return ActivityStore.getUserPatientLinks({
          user: this.case.userSummary,
          patient: this.case.patientSummary,
          fromDate: this.case.minEventDate,
          toDate: this.case.maxEventDate,
        });
      }

      return ActivityStore.getUserLink({
        // fallback to user snapshot id if user summary is null, these ids should be the same
        user: this.case.userSummary ?? { id: this.case.userSnapshot?.id },
        fromDate: this.case.minEventDate,
        toDate: this.case.maxEventDate,
      });
    }
    return {};
  }

  personLink = person =>
    `/activity/${person === 'user' ? 'employee' : 'patient'}/${
      this[`${person}Id`]
    }?fromDate=${moment(this.case.minEventDate).format(
      'MM-DD-YYYY'
    )}&toDate=${moment(this.case.maxEventDate).format('MM-DD-YYYY')}`;

  // Computed
  get eventLabel() {
    if (this.type === 'diversion') return 'Medication Events';
    return 'Access Events';
  }

  // Computed
  get actions() {
    let { actions } = this.case;
    if (!actions) actions = [];

    return actions.sort((a, b) => {
      if (a.date > b.date) return -1;
      if (a.date < b.date) return 1;
      return 0;
    });
  }

  // Computed
  get analyticAssessments() {
    return (this.case.analyticAssessments || []).filter(
      ({ reasons = [], clusterReasons = [] }) => {
        if (reasons !== undefined && reasons.length > 0) {
          return reasons.length;
        } else {
          return clusterReasons.length;
        }
      }
    );
  }

  get assessmentsHeadlines() {
    return getAssessmentsHeadlines({
      assessments: this.analyticAssessments,
      incidents: this.incidentsByPeriod,
      type: this.type,
    });
  }

  // Computed
  get assessmentLinkTo() {
    if (this.type === 'diversion') return `/case/${this.caseId}/incidents`;
    else if (this.type === 'privacy' && this.case.patient)
      return `/case/${this.caseId}/accesses`;
    return '';
  }

  // Computed
  get href() {
    return HalUtils.getSelf(this.case);
  }

  // Computed
  get owner() {
    const c = this.case;
    return c && c.owner && c.owner.id;
  }

  // Computed
  get resolution() {
    return this.case?.resolution;
  }

  // Computed
  get created() {
    return this.case.created;
  }

  // Computed
  get createdBy() {
    const c = this.case;
    return c && c.createdBy && c.createdBy.id;
  }

  // Computed
  get isOwner() {
    return this.owner && this.owner === LoginStore.id;
  }

  // Computed
  get isCreator() {
    return this.createdBy && this.createdBy === LoginStore.id;
  }

  // Computed
  get editReason() {
    if (PermissionStore.has('CASE_MODIFY')) return true;

    const { isOwner, resolution } = this;
    const defaultReason = "You don't have permission to modify the case.";

    if (isOwner) {
      if (
        PermissionStore.hasAll(
          'CASE_MODIFY_NOTES_OWNED',
          'CASE_MODIFY_ASSESSMENT_OWNED'
        )
      ) {
        // we're okay but perform further checks
      } else return defaultReason;
      // not owner
    } else if (
      PermissionStore.hasAll('CASE_MODIFY_NOTES', 'CASE_MODIFY_ASSESSMENT')
    ) {
      // we're okay but perform further checks
    } else return defaultReason;

    if (resolution === null || resolution === undefined) {
      // case isn't resolved yet but that's fine
    } else return 'This case is resolved.';

    return true;
  }

  // Computed
  get editable() {
    return this.editReason === true;
  }

  // Computed
  get readOnly() {
    // if no case ID that means the user is currently creating a GRC case.
    return !this.editable && !!this.caseId;
  }

  // Computed
  get canDelete() {
    if (PermissionStore.has('CASE_DELETE')) return true;
    if (this.isOwner && PermissionStore.has('CASE_DELETE_OWNED')) return true;
    if (this.isCreator && PermissionStore.has('CASE_DELETE_CREATED_BY'))
      return true;
    return false;
  }

  // Computed
  get canResolve() {
    // force (at least in UI) the case to be reopened first
    if (this.resolution) return false;

    if (PermissionStore.has('CASE_RESOLVE')) return true;
    if (this.isOwner && PermissionStore.has('CASE_RESOLVE_OWNED')) return true;
    return false;
  }

  // Computed
  get canReopen() {
    if (PermissionStore.has('CASE_REOPEN')) return true;
    if (this.isOwner && PermissionStore.has('CASE_REOPEN_OWNED')) return true;
    return false;
  }

  // Computed
  get canModifyCategory() {
    if (PermissionStore.has('CASE_MODIFY_CATEGORY')) return true;
    if (this.isOwner && PermissionStore.has('CASE_MODIFY_CATEGORY_OWNED'))
      return true;
    return false;
  }

  // Computed
  get canModifyUserType() {
    if (PermissionStore.has('CASE_MODIFY_USER_TYPE')) return true;
    if (this.isOwner && PermissionStore.has('CASE_MODIFY_USER_TYPE_OWNED'))
      return true;
    return false;
  }

  // Computed
  get canModifyActions() {
    if (PermissionStore.has('CASE_MODIFY_ACTIONS')) return true;
    if (this.isOwner && PermissionStore.has('CASE_MODIFY_ACTIONS_OWNED'))
      return true;
    return false;
  }

  // Computed
  get canModifyNotes() {
    if (PermissionStore.has('CASE_MODIFY_NOTES')) return true;
    if (this.isOwner && PermissionStore.has('CASE_MODIFY_NOTES_OWNED'))
      return true;
    return false;
  }

  get canModifyGRCCaseTitle() {
    if (
      PermissionStore.hasAll(
        'GRC_CASE_MODIFY_TITLE',
        'GRC_CASE_MODIFY_TITLE_OWNED'
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  // Computed
  get canModifyAssessment() {
    if (
      this.resolution &&
      PermissionStore.has('CASE_MODIFY_ASSESSMENT_RESOLVED')
    )
      return true;
    if (PermissionStore.has('CASE_MODIFY_ASSESSMENT')) return true;
    if (this.isOwner && PermissionStore.has('CASE_MODIFY_ASSESSMENT_OWNED'))
      return true;

    return false;
  }

  // Computed
  get canModifyAttachments() {
    if (PermissionStore.has('CASE_MODIFY_ATTACHMENTS')) return true;
    if (this.isOwner && PermissionStore.has('CASE_MODIFY_ATTACHMENTS_OWNED'))
      return true;
    return false;
  }

  // Computed
  get sortBy() {
    const { sortBy } = parseSort(this.sort);
    return sortBy;
  }

  // Computed
  get sortDir() {
    const { sortDir } = parseSort(this.sort);
    return sortDir;
  }

  // Computed
  get eventData() {
    const eventData = [];

    postProcessEvents(
      eventData,
      this.case.accesses,
      'dateOfAccess',
      a => ActivityDescriptionStore.get(a.activityId) || a.type
    );
    postProcessEvents(
      eventData,
      CaseAccessesStore.userOnlyAccesses,
      'dateOfAccess',
      a => ActivityDescriptionStore.get(a.activityId) || a.type
    );

    return eventData.sort((a, b) => {
      const aValue = a[this.sortBy] || '';
      const bValue = b[this.sortBy] || '';
      if (aValue < bValue) return this.sortDir === 'asc' ? -1 : 1;
      if (aValue > bValue) return this.sortDir === 'asc' ? 1 : -1;
      return 0;
    });
  }

  // Computed
  get eventTypes() {
    const c = this.case;
    // these names must match up in CaseAccessesStore.js
    const types = [
      'administrations',
      'discrepancies',
      'handlings',
      'incident Summary',
      'incident Summary & Event Details',
      'orders',
    ].map(e => {
      if (
        e === 'incident Summary' ||
        e === 'incident Summary & Event Details'
      ) {
        return {
          name: e.capitalizeFirstLetter(),
          active:
            this.medicationIncidents && this.medicationIncidents.length > 0,
        };
      }
      const active =
        this.medicationIncidents &&
        this.medicationIncidents.some(inc => inc[e] && inc[e].length > 0);
      return { name: e.capitalizeFirstLetter(), active };
    });

    types.unshift({
      name: 'Accesses',
      active: c && c.accesses && c.accesses.length > 0,
    });

    return types;
  }

  // Computed
  get dirty() {
    return (
      this.currentAction !== null ||
      this.editingNote !== null ||
      this.editingAssessment ||
      this.deletingAssessment ||
      this.deletingNote !== null ||
      this.deletingAction !== null ||
      this.isCaseTypeDirty ||
      this.isUserRoleDirty ||
      this.isOwnerDirty ||
      this.isResolutionDirty ||
      this.commentFormActive
    );
  }

  // Computed
  get temporaryPermissionAvailable() {
    return (
      TemporaryPermissionsSettingsStore.enabled &&
      TemporaryPermissionsSettingsStore.creationPrompt &&
      this.type === 'privacy' &&
      this.isResolutionDirty &&
      this.newResolution === 'NOT_VIOLATION_FALSE_POSITIVE' &&
      this.patient // the check for the patient rules out user only privacy cases
    );
  }

  // Computed
  get createTemporaryPermission() {
    return (
      this.type === 'privacy' &&
      this.isResolutionDirty &&
      this.newResolution === 'NOT_VIOLATION_FALSE_POSITIVE' &&
      (this.temporaryPermissionCreate ||
        (TemporaryPermissionsSettingsStore.enabled &&
          !TemporaryPermissionsSettingsStore.creationPrompt))
    );
  }

  // Computed
  get canViewAuths() {
    return Boolean(
      PermissionStore.has('ACCESS_AUTH_SEARCH') &&
        this.type === 'privacy' &&
        this.patient
    );
  }

  get userCases() {
    return EmployeeCasesStore.infoById.get(this.userId) || [];
  }

  get otherUserCases() {
    return this.userCases.filter(c => c.id !== this.caseId);
  }

  get externalId() {
    return this.case && this.case.externalId;
  }

  get radarLink() {
    return (
      (this.case &&
        this.case.externalId &&
        `https://app.radarfirst.com/incidents/?iid=${this.case.externalId}#/incidents/show`) ||
      ''
    );
  }

  get canSync() {
    return (
      PermissionStore.has('CASE_SYNC') ||
      (PermissionStore.has('CASE_SYNC_OWNED') && this.isOwner)
    );
  }

  // Action
  setCaseId = id => (this.caseId = id);

  // Action
  setFilters(query) {
    const { sort } = query;
    this.sort = sort || DEFAULT_SORT;
  }

  // Action
  setNewResolution = val => {
    this.newResolution = val;
  };

  // Action
  setUploadingNote = val => {
    this.uploadingNote = val;
  };

  // Action
  setNewTypeCategory = val => {
    this.newTypeCategory = val;
  };

  // Action
  setNewUserType = val => {
    this.newUserType = val;
  };

  // Action
  setNewOwner = val => {
    this.newOwner = val;
  };

  // Action
  setCaseMedicationIncidents = () => {
    if (this.caseId && this.type === 'diversion') {
      CaseClient.getMedicationIncidents(this.caseId).then(resp => {
        this.caseMedicationIncidents = resp;
        this.caseIncidentsLoading = false;
      });
    } else {
      return [];
    }
  };

  // Computed
  get isCaseIncidentsLoading() {
    return this.caseIncidentsLoading;
  }

  // Computed
  get isCaseTypeDirty() {
    return (
      !!this.newTypeCategory &&
      this.newTypeCategory.id !== (this.typeCategory || {}).id
    );
  }

  // Computed
  get isUserRoleDirty() {
    return (
      !!this.newUserType && this.newUserType.id !== (this.userType || {}).id
    );
  }

  // Computed
  get isOwnerDirty() {
    const owner = this.owner || 'Unassigned';
    return !!this.newOwner && this.newOwner !== owner;
  }

  // Computed
  get isResolutionDirty() {
    const resolution = this.resolution || 'Unresolved';
    return !!this.newResolution && this.newResolution !== resolution;
  }

  // Computed
  get multiPatient() {
    return this.type === 'privacy' && !this.patient;
  }

  // Computed
  get multiPatientLink() {
    return this.personLink('user');
  }

  fetch() {
    // register user activity during case modification/refreshes
    LoginStore.renewCheck();
    if (this.caseId) {
      return CaseClient.get(this.caseId);
    }
  }

  reset = () => {
    this.deleting = false;
    this.draftNotes = '';
    this.draftAssessment = '';
    this.deletingAction = null;
    this.deletedAttachment = null;
    this.deletingAttachment = null;
    this.deletingNote = null;
    this.editingNote = null;
    this.temporaryPermissionCreate = false;
    this.temporaryPermissionEndDate = moment().add(
      TemporaryPermissionsSettingsStore.duration,
      'days'
    );
    this.temporaryPermissionReason = null;
    this.reopeningCaseFromTimeline = false;
    this.setUploadingNote(false);
    this.deletingAssessment = false;
    this.editingAssessment = false;
    this.incidentFilterParams = {};
    this.newTypeCategory = null;
    this.newUserType = null;
    this.newOwner = null;
    this.newResolution = null;
  };

  updateCase = updated => {
    return CaseClient.update(this.href, updated, true).then(r => {
      if (updated.category) {
        // full synchronize required
        CaseUtils.synchronize();
      } else {
        CaseUtils.synchronizeActivity();
      }
      // at this point, merge the updates into the current case.
      // if the save was called on navigation away from the page, the
      // response may be null, so check for that
      if (this.response !== null && this.response !== undefined)
        Object.assign(this.response, updated);
      this.refresh();
      this.reset();
      return r;
    });
  };

  /**
   * Add an action to the case.
   *
   * @param {Object} newAction   The ID of the action type and date for the
   *                             case action. Expected to follow the form of
   *                             {
   *                               type: "abcdef123456",
   *                               date: "2016-08-29T00:00:00.0000+000",
   *                               notes: "lorem ipsum dolor."
   *                             }
   *                             where "type" is the ID of the case action type
   * @return {Promise}           An HTTP Promise
   */
  addAction(newAction) {
    return CaseClient.addAction(this.href, newAction).then(() => {
      this.refresh();
    });
  }

  /**
   * Adds a set of attachments to the case
   *
   * @param {Object} attachments one or more attachments to add to the case
   * @return {Promise}           an HTTP Promise
   */
  addAttachments(attachments) {
    return CaseClient.addAttachments(this.href, attachments).then(() => {
      this.refresh();
    });
  }

  /**
   * Downloads a file attached to the case
   *
   * @param {Number} attachmentIndex the index of the attachment event
   * @param {String} fileId          the id of the file
   * @param {String} fileName        the name of the file
   *
   * @returns {void}
   */
  downloadAttachment = (attachmentIndex, fileId, fileName) => {
    DownloadUtils.downloadFromServer(
      CaseClient.downloadAttachment(this.href, fileId),
      fileName
    );
  };

  /**
   * Removes a single attachment (set of files) from the case.
   *
   * @param {Integer} attachmentIndex  The index of the attachment in the Array
   * @return {Promise}                 An HTTP Promise
   */
  removeAttachment = attachmentIndex => {
    this.deleting = true;
    const updated = {};

    updated.attachments = (this.case.attachments || []).slice();
    updated.attachments.splice(attachmentIndex, 1);

    // important: for optional fields we want to send null
    // otherwise they will inherit such fields from other attachments
    updated.attachments.forEach(a => (a.notes = a.notes || null));

    return this.updateCase(updated)
      .fail(() => {
        const content = (
          <span>
            <i className="material-icons icon-warning" />
            There was a problem removing the attachment
          </span>
        );
        NotificationStore.add({ level: 'warning', content });
      })
      .always(() => {
        this.setValue(null, 'deletedAttachment');
        this.setValue(null, 'deletingAttachment');
      });
  };

  /**
   * Remove a single action from the case.
   *
   * @param {Integer} actionIndex   The index of the action in the Array
   * @return {Promise}              An HTTP Promise
   */
  removeAction = actionIndex => {
    const updated = {};

    updated.actions = (this.case.actions || []).slice();
    updated.actions.splice(actionIndex, 1);

    // important: for optional fields we want to send null
    // otherwise they will inherit such fields from other attachments
    updated.actions.forEach(a => {
      a.notes = a.notes || null;
      a.type = a.type.id;
    });

    return this.updateCase(updated);
  };

  /**
   * Add a note to the case
   *
   * @param {String} newNote  the free-form text notes
   * @return {Promise}        An HTTP Promise
   */
  addNote(newNote) {
    if (this.uploadingNote) return Promise.resolve();
    return Promise.resolve()
      .then(() => this.setUploadingNote(true))
      .then(() => CaseClient.addNote(this.href, newNote))
      .finally(() => this.setUploadingNote(false))
      .then(() => {
        // Do not return; refresh() may fail if we need to renew login first.
        this.refresh();
      });
  }

  /**
   * Remove a single note from the case.
   *
   * @param {Integer} noteIndex The index of the note in the Array
   * @return {Promise}          An HTTP Promise
   */
  removeNote = noteIndex => {
    const updated = {};

    updated.notes = (this.case.notes || []).slice();
    updated.notes.splice(noteIndex, 1);

    return this.updateCase(updated);
  };

  editNote = (noteIndex, newNote) => {
    const updated = {};
    updated.notes = (this.case.notes || []).slice();
    updated.notes.splice(noteIndex, 1, {
      ...this.case.notes[noteIndex],
      notes: newNote,
    });
    return this.updateCase(updated);
  };

  delete(reason) {
    return CaseClient.delete(this.href, reason).then(r => {
      // go ahead and refresh this here
      // if we wait til they navigate to my cases, it's kind of slow.
      CaseUtils.synchronize();
      return r;
    });
  }

  assign(ownerId, message) {
    return CaseClient.assign(this.href, {
      newOwnerId: ownerId,
      message,
    }).always(() => {
      this.refresh();
    });
  }

  unassign() {
    return CaseClient.unassign(this.href).then(() => {
      this.refresh();
    });
  }

  print() {
    CaseClient.print(this.href);
  }

  /**
   * Update a case as being resolved.
   *
   * @param {Object} opts   The resolution state options
   * @return {Promise}      An HTTP Promise
   */
  resolve(opts) {
    return CaseClient.resolve(this.href, opts).then(() => {
      this.refresh();
    });
  }

  setIncidentFilterParams = params =>
    (this.incidentFilterParams = { ...params });

  reopen = existingPermission => {
    return CaseClient.reopen(this.href).then(() => {
      if (existingPermission) {
        const content = (
          <Link to={`/authorizations/${existingPermission.id}`}>
            <i className="material-icons icon-warning" />
            Heads up! This case previously created a temporary permission.
          </Link>
        );

        NotificationStore.add({ level: 'warning', content });
      }
      this.refresh();
      this.reset();
    });
  };

  // action
  setValue = (val, field) => {
    this[field] = val;
    // keep the user active if they're typing up some complex notes/assessments
    if (field === 'draftNotes' || field === 'draftAssessment')
      LoginStore.renewCheck();
  };

  linkForAccessTableEvent = data => {
    return HalUtils.buildActivityViewLink({
      userId: this.userId,
      patientId: data.patient?.id,
      maxEventDate: this.maxEventDate,
      minEventDate: this.minEventDate,
      focusEvent: data.id,
      type: data.patient ? 'event' : 'userOnlyEvents',
    });
  };

  // action
  updateIncidentList = item => {
    if (item.id)
      this.caseMedicationIncidents = this.updatedIncidentList({
        item,
        incidents: this.medicationIncidents,
      });
    else this.refresh();
  };

  setIsSyncing = val => (this.isSyncing = val);

  setSyncLoadingMsg = val => (this.syncLoadingMsg = val);

  sync = () => {
    this.setIsSyncing(true);

    if (!this.externalId) this.setSyncLoadingMsg(CREATE_IN_PROGRESS);
    else this.setSyncLoadingMsg(SYNC_IN_PROGRESS);

    return CaseClient.sync(this.href)
      .then(() => toast.success(SYNC_SUCCESS))
      .catch(() => toast.error(SYNC_ERROR))
      .then(() => this.refresh())
      .then(() => this.setIsSyncing(false));
  };

  // For custom print options
  selectedCaseflowOption = null;
  caseflowFieldsToInclude = null;
  filteredAssessmentsForPrint = null;
  setCaseflowPrintOptionsForCaseStore = (opt, fields, assessments) => {
    this.selectedCaseflowOption = opt;
    this.caseflowFieldsToInclude = fields;
    this.filteredAssessmentsForPrint = assessments;
  };

  selectedDetailsOption = null;
  setDetailsPrintOptionsForCaseStore = opt => {
    this.selectedDetailsOption = opt;
  };

  incidentsForPrintMainOption = null;
  filteredIncidentsForPrint = null;
  setIncidentPrintOptionsForCaseStore = (opt, incidents) => {
    this.incidentsForPrintMainOption = opt;
    this.filteredIncidentsForPrint = [...incidents].map(inc => ({
      ...inc,
      forPrint: true,
    }));
  };
}

decorate(CaseStore, {
  // Observables
  caseIncidentsLoading: observable,
  caseMedicationIncidents: observable,
  caseId: observable,
  commentFormActive: observable,
  currentAction: observable,
  deleting: observable,
  deletingAction: observable,
  deletedAttachment: observable,
  deletingAttachment: observable,
  deletingNote: observable,
  deletingAssessment: observable,
  editingAssessment: observable,
  editingNote: observable,
  draftNotes: observable,
  draftAssessment: observable,
  reopeningCaseFromTimeline: observable,
  newTypeCategory: observable,
  newOwner: observable,
  newResolution: observable,
  newUserType: observable,
  incidentFilterParams: observable,
  sort: observable,
  temporaryPermissionCreate: observable,
  temporaryPermissionEndDate: observable,
  temporaryPermissionReason: observable,
  uploadingAttachment: observable,
  uploadingNote: observable,
  isSyncing: observable,
  syncLoadingMsg: observable,
  caseflowFieldsToInclude: observable,
  selectedCaseflowOption: observable,
  selectedDetailsOption: observable,
  incidentsForPrintMainOption: observable,
  filteredIncidentsForPrint: observable,
  filteredAssessmentsForPrint: observable,
  // Computeds
  isCaseIncidentsLoading: computed,
  actions: computed,
  analyticAssessments: computed,
  assessmentsHeadlines: computed,
  assessmentLinkTo: computed,
  canDelete: computed,
  canModifyActions: computed,
  canModifyAssessment: computed,
  canModifyAttachments: computed,
  canModifyGRCCaseTitle: computed,
  canModifyCategory: computed,
  onNoteChange: observable,
  canModifyNotes: computed,
  canModifyUserType: computed,
  canReopen: computed,
  canResolve: computed,
  canSync: computed,
  canViewAuths: computed,
  case: computed,
  created: computed,
  createdBy: computed,
  dirty: computed,
  editable: computed,
  editReason: computed,
  eventData: computed,
  eventLabel: computed,
  eventTypes: computed,
  externalId: computed,
  href: computed,
  incidentsFilteredByParams: computed,
  incidentRangeFilterOptions: computed,
  incidentStatusFilterOptions: computed,
  incidentTypeFilterOptions: computed,
  isCreator: computed,
  isOwner: computed,
  lastModified: computed,
  lastUpdatedByAG: computed,
  links: computed,
  maxEventDate: computed,
  medicationIncidents: computed,
  minEventDate: computed,
  multiPatient: computed,
  multiPatientLink: computed,
  number: computed,
  otherUserCases: computed,
  owner: computed,
  patient: computed,
  patientId: computed,
  patientFullName: computed,
  radarLink: computed,
  readOnly: computed,
  reasons: computed,
  resolution: computed,
  sortBy: computed,
  sortDir: computed,
  temporaryPermissionAvailable: computed,
  title: computed,
  type: computed,
  typeCategory: computed,
  isSelfAccess: computed,
  userCases: computed,
  userType: computed,
  userId: computed,
  user: computed,
  userFullName: computed,
  userRoleEmails: computed({ equals: comparer.structural }),
  isCaseTypeDirty: computed,
  isUserRoleDirty: computed,
  isOwnerDirty: computed,
  isResolutionDirty: computed,
  incidentsByPeriod: computed,
  unreconciledDrugs: computed,
  // Actions
  setCaseId: action,
  setFilters: action,
  setNewResolution: action,
  setUploadingNote: action,
  setNewTypeCategory: action,
  setNewUserType: action,
  setNewOwner: action,
  setIncidentFilterParams: action,
  setValue: action,
  sync: action,
  updateIncidentList: action,
  setIsSyncing: action,
  setSyncLoadingMsg: action,
  setCaseflowPrintOptionsForCaseStore: action,
  setDetailsPrintOptionsForCaseStore: action,
  setIncidentPrintOptionsForCaseStore: action,
  setCaseMedicationIncidents: action,
});

export default new CaseStore({
  incidentDescriptionStore: IncidentDescriptionStore,
});
